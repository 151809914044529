export default {
	cashy: {
		bannerTitle: 'Receba e pague mais rapidamente',//更快地收款和付款
		bannerText: 'Com apenas uma integração, sua empresa pode aceitar pagamentos no Brasil. Projetada especificamente para o mercado brasileiro, a Cashy é uma boa solução para suas necessidades de serviços de cobrança e pagamento on-line.',
		contactBtn: 'Entre em contato conosco',
		answer: 'Para todas as suas necessidades de transações on-line',
		cashyState: 'A Cashy é um gateway de pagamento que ajuda as empresas a oferecer a seus clientes uma maneira simples, segura e conveniente de escolher pagamentos. Atendendo com eficiência aos clientes de comércio eletrônico, jogos e outros, ele ajuda as empresas a crescerem rapidamente, fornecendo um conjunto de APIs de alta qualidade.',
		integration: 'Simples de integrar',
		speed: 'Rápido',
		customer: 'Atendimento ao cliente profissional',
		integrationText: 'A integração da API facilita o uso da Cashy. Você pode simplesmente se inscrever e processar em nosso ambiente de teste.',
		speedText: 'Entre em operação em apenas um dia e use a Cashy para transações em tempo real imediatamente. Você pode visualizar todas as suas transações em um back office unificado, mostrando estatísticas diárias fáceis de visualizar.',
		customerText: 'Aproveite a resposta rápida e o suporte da nossa equipe de atendimento ao cliente. Nossa equipe está sempre à disposição para oferecer uma experiência personalizada de atendimento ao cliente.',
		products: 'Produtos',//产品
		payin: 'Coleções',//代收
		payout: 'Colecionar',//代付
		contact: 'Entre em contato conosco',//联系我们
		office: 'Endereço da empresa',//公司地址
		productsName:"pagamento agregado",
	},
}
