export default {
    cashy: {
        bannerTitle: 'Accept and send payments faster',
        bannerText: 'With a single integration, enable your business to accept payments in Brazil. Specifically designed for the Brazil market, Cashy is the solution for your online payment and disbursement service needs.',
        contactBtn: 'Contact us',
        answer: 'THE ANSWER TO ALL YOUR ONLINE TRANSACTION NEEDS',
        cashyState: 'Cashy is a payment gateway that helps businesses make payments simple, secure and easy for customers.  helping businesses grow by providing a suite of world-class APIs.',
        integration: 'Simple integration',
        speed: 'Speed',
        customer: 'Customer Service',
        integrationText: 'Get started with Cashy easily with your choice of API integration. Try it out with our sandbox environment, where you can start testing with a simple login.',
        speedText: 'Go live in less than a day, and start making live transactions with Cashy. Access your transaction history on a centralized dashboard, with daily reconciliation for visibility and easy reporting.',
        customerText: 'Enjoy responsive and timely support from our customer service team. Experience personalized customer service with our team on standby.',
        products: 'Products',
        payin: 'Payin',
        payout: 'Payout',
        contact: 'Contact us',
        office: 'Office',
        productsName:"aggregate payment",
    }
}