export default {
	cashy: {
		bannerTitle: '更快地收款和付款',
		bannerText: '只需一次集成，你的企业就可以在印度尼西亚接受付款。Cashy专门为印度尼西亚市场设计，是满足你在线收款和付款服务需求的良好解决方案。',
		contactBtn: '联系我们',
		answer: '满足你所有的在线交易需求',
		cashyState: 'Cashy 是一个支付网关，能帮助企业为客户提供简单、安全和方便的支付选择方式。通过提供一套高质量的API帮助企业发展。',
		integration: '集成简单',
		speed: '速度快',
		customer: '专业客服',
		integrationText: '集成API后可以轻松使用Cashy。你只需简单注册即可在我们的测试环境中进行处理。',
		speedText: '最快在一天之内上线，马上使用Cashy进行实时交易。你可以在统一的后台查看所有的交易记录，展示简单可见的每日统计。',
		customerText: '享受我们客户服务团队的及时响应和支持。我们的团队随时待命，让你体验个性化的客户服务。',
		products: '产品',
		payin: '代收',
		payout: '代付',
		contact: '联系我们',
		office: '公司地址',
	},
}
